<template>
	<div>
		<!-- Table Container Card -->
		<b-card no-body class="mb-0">
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="6"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Show</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>entries</label>
					</b-col>
					<!-- Search & Download as CSV-->
					<b-col cols="12" md="6" class="d-flex justify-content-end align-items-center">
						<div class="d-flex align-items-center justify-content-end">
							<b-dropdown split dropleft size="sm">
								<template #button-content>
									<b-button @click="fetchClients" size="sm">
										<span class="text-nowrap">Filter</span>
									</b-button>
								</template>

								<div class="p-1 dropdown-form-container">
									<div class="font-small-3 font-weight-bold text-primary text-right mb-1" type="button" @click="resetFilters">Reset Filters</div>

									<!-- Filters Dropdown -->
									<b-dropdown-form class="flex column">
										<b-form-group @submit.stop.prevent>
											<div class="position-relative">
												<b-form-input class="search-group-input" placeholder="Name..." v-model="nameQuery" />
											</div>
											<div class="position-relative mt-1">
												<b-form-input class="search-group-input" placeholder="Email..." v-model="emailQuery" />
											</div>
											<div class="position-relative mt-1">
												<b-form-input class="search-group-input" placeholder="Phone..." v-model="phoneQuery"/>
											</div>
										</b-form-group>

										<b-form-group>
											<label for="">Client Verification</label>
											<b-form-select v-model="isVerifiedQuery"
													 :options="clientVerificationOptions" placeholder="Client Verification" />
										</b-form-group>

										<div class="date-filters-container d-flex flex-wrap flex-md-nowrap mt-2">
											<b-form-group @submit.stop.prevent class="width-49perc">
												<label for="start-range">Accounts created from</label>
												<b-form-input id="start-range" v-model="startRangeQuery" type="date" class="color-inherit" :max="dateRange.max"/>
											</b-form-group>
											<b-form-group @submit.stop.prevent class="width-49perc ml-0 ml-md-1">
												<label for="end-range">Accounts created to</label>
												<b-form-input id="end-range" v-model="endRangeQuery" type="date" class="color-inherit" :min="dateRange.min" :max="maxDate"/>
											</b-form-group>
										</div>
									</b-dropdown-form>
								</div>
							</b-dropdown>
						</div>
						<!-- Download data as CSV -->
						<span title="Download as CSV" type="button" @click="downloadCSVData" class="ml-2">
							<feather-icon
								icon="DownloadCloudIcon"
								size="25"
							/>
						</span>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="clientsTable"
				class="position-relative text-nowrap"
				:items="clients"
				responsive
				:fields="tableColumns"
				show-empty
				empty-text="No matching records found"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
			>

				<!-- Column: Name -->
				<template #cell(fullName)="data">
					<b-link class="font-weight-bold d-block text-nowrap"
									:to="{name: 'UsersEdit', params: {userId: data.item.id}}">
						{{ data.item.fullName }}
					</b-link>
				</template>

				<template #cell(emailAddress)="data">
					<a :href="'mailto:'+data.item.emailAddress" class="font-weight-bold d-block text-nowrap">
						{{ data.item.emailAddress }}
					</a>
				</template>

				<template #cell(cellPhone)="data">
					<a :href="'tel:'+data.item.cellPhone" class="font-weight-bold d-block text-nowrap">
						{{ data.item.cellPhone }}
					</a>
				</template>

				<template #cell(accountCreatedOn)="data">
					<span>
						{{ getFormattedDate(data.item.accountCreatedOn) }}
					</span>
				</template>

				<template #cell(country)="data">
					<span>
						{{ data.item.country ? data.item.country : 'Not Selected' }}
					</span>
				</template>

				<template #cell(isVerified)="data">
					<b-avatar
						size="32"
						:variant="getActiveVariant(data.item.isVerified)"
					>
						<feather-icon
							:icon="getActiveIcon(data.item.isVerified)"
						/>
					</b-avatar>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalClients"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18"/>
							</template>

							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18"/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BPagination,
	BLink,
	BAvatar,
	BBadge,
	BDropdown,
	BDropdownItem,
	BFormGroup, BDropdownForm, BFormSelect,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import moment from 'moment';
import exportCsvFile from '@core/utils/export-csv-file'

export default {
	components: {
		BFormSelect, BDropdownForm, BFormGroup,
		BCard,
		BRow,
		BCol,
		BLink,
		BAvatar,
		BBadge,
		BFormInput,
		BButton,
		BTable,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},

	data() {
		return {
			currentPage: 1,
			perPage: 100,
			perPageOptions: [10, 25, 50, 100],
			nameQuery: '',
			emailQuery: '',
			phoneQuery: '',
			isVerifiedQuery: '',
			startRangeQuery: '',
			endRangeQuery: '',
			tableColumns: [
				{
					key: 'fullName',
					label: 'Name',
					sortable: true
				},
				{
					key: 'emailAddress',
					label: 'Email',
					sortable: true
				},
				{
					key: 'cellPhone',
					label: 'Phone',
					sortable: true
				},
				{
					key: 'country',
					label: 'Country',
					sortable: true
				},
				{
					key: 'isVerified',
					label: 'Verified',
					sortable: true
				},
				{
					key: 'accountCreatedOn',
					label: 'Created At',
					sortable: true
				}
			],
			sortBy: 'accountCreatedOn',
			sortDesc: true,
			clients: [],
			totalClients: 0,
			clientVerificationOptions: [
				{ text: 'Verified', value: true},
				{ text: 'Not Verified', value: false}
			],
			dateRange: {
				min: null,
				max: null
			}
		};
	},

	computed: {
		dataMeta() {
			return {
				from: this.perPage * (this.currentPage - 1) + (this.clients.length ? 1 : 0),
				to: this.perPage * (this.currentPage - 1) + this.clients.length,
				of: this.totalClients,
			}
		},

		queryParams() {
			return {
				sortBy: this.sortBy,
				sortOrder: this.sortDesc ? 1 : 0,
				skip: (this.currentPage - 1) * this.perPage,
				take: this.perPage,
			}
		},

		maxDate() {
			let today = new Date();
			let dd = today.getDate();
			let mm = today.getMonth() + 1;
			const yyyy = today.getFullYear();

			if (dd < 10) {
				dd = '0' + dd;
			}
			if (mm < 10) {
				mm = '0' + mm;
			}

			today = yyyy + '-' + mm + '-' + dd;

			return today;
		}
	},

	watch: {
		queryParams: {
			handler() {
				this.fetchClients();
			},
			immediate: true
		},
		startRangeQuery: {
			handler(newValue) {
				this.dateRange.min = newValue ? newValue : null;
			},
			immediate: true
		},
		endRangeQuery: {
			handler(newValue) {
				this.dateRange.max = newValue ? newValue : this.maxDate;
			},
			immediate: true
		}
	},

	methods: {
		async fetchClients() {
			const params = this.queryParams;
			params.name = this.nameQuery;
			params.email = this.emailQuery;
			params.cellPhone = this.phoneQuery;
			params.isVerified = this.isVerifiedQuery;
			params.startRange = this.startRangeQuery;
			params.endRange = this.endRangeQuery;

			const response = await this.$http.get('clients', {
				params
			});
			this.clients = response.data.results ?? [];
			this.totalClients = response.data.count;
		},

		getActiveVariant(active) {
			return active ? 'light-success' : 'light-danger';
		},

		getActiveIcon(active) {
			return active ? 'CheckCircleIcon' : 'XIcon';
		},

		getFormattedDate(date) {
			return moment.utc(date).format('yyyy-MM-DD');
		},

		resetFilters() {
			this.nameQuery = '';
			this.emailQuery = '';
			this.phoneQuery = '';
			this.isVerifiedQuery = '';
			this.startRangeQuery = '';
			this.endRangeQuery = '';
		},

		downloadCSVData() {
			const headers = {
				seq: 'Seq',
				clientName: 'Client name',
				clientEmail: 'Client Email',
				clientPhone: 'Client Phone',
				clientCountry: 'Client Country',
				clientVerified: 'Client Verification',
				createdOn: 'Created At'
			};

			const items = [];
			const clients = this.clients;

			for (let i = 0; i < clients.length; i++) {
				const args = {
					seq: i + 1,
					clientName: clients[i].fullName,
					clientEmail: clients[i].emailAddress,
					clientPhone: clients[i].cellPhone,
					clientCountry: clients[i].country ? clients[i].country : 'Not Selected',
					clientVerified: clients[i].isVerified ? 'Verified' : 'Not Verified',
					createdOn: this.getFormattedDate(clients[i].accountCreatedOn)
				};

				items.push(args);
			}

			const filename = "Bainkom_Clients";

			exportCsvFile(headers, items, filename);
		}
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
